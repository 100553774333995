import { createSlice } from "@reduxjs/toolkit";
import {
  getLightColorButton,
  getLogoDarkBackground,
  getLogoLightBackground,
  getPrimaryColorButton,
} from "../../services/whitelabel";
const initialState = {
  usuarioLogado: {},
  companyBase: {},
  permissions: {},
  autenticacaoExterna: false,
  keepQuitaLogo: false,
  coresPadroes: {},
  logos: {},
  whatsappSuport: "55999042233",
  idCompanyBind: null,
  firstAccess: false,
  isQuitaboletos: false,
  urlLogo: "",
};
const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsQuitaboletos(state, action) {
      state.isQuitaboletos = action.payload;
    },
    setUsuarioLogado(state, action) {
      state.usuarioLogado = action.payload.usuarioLogado;
    },
    setAutenticacaoExterna(state) {
      state.autenticacaoExterna = true;
    },
    setCoresPadroes(state, action) {
      state.coresPadroes = action.payload;
    },
    setLogos(state, action) {
      state.logos = action.payload;
    },
    setKeepQuitaLogo(state, action) {
      state.keepQuitaLogo = action.payload;
    },
    setWhatsappSuport(state, action) {
      state.whatsappSuport = action.payload;
    },
    setIdCompanyBind(state, action) {
      state.idCompanyBind = action.payload;
    },
    setFirstAccess(state, action) {
      state.firstAccess = action.payload;
    },
    resetUser(state) {
      state.usuarioLogado = initialState.usuarioLogado;
      state.companyBase = initialState.companyBase;
      state.permissions = initialState.permissions;
    },
    resetWhitelabel(state) {
      state.autenticacaoExterna = initialState.autenticacaoExterna;
      state.coresPadroes = {
        primaryColorButton: getPrimaryColorButton(),
        lightColorButton: getLightColorButton(),
      };
      state.logos = {
        lightBackground: getLogoLightBackground(),
        darkBackground: getLogoDarkBackground(),
      };
      state.keepQuitaLogo = initialState.keepQuitaLogo;
      state.idCompanyBind = initialState.idCompanyBind;
      state.whatsappSuport = initialState.whatsappSuport;
      state.urlLogo = initialState.urlLogo;
    },
    setPermissions(state, action) {
      state.permissions = action.payload;
    },
    setCompanyBase(state, action) {
      state.companyBase = action.payload;
    },
    setUrlLogo(state, action) {
      state.urlLogo = action.payload;
    },
  },
});

export default user.reducer;

export const {
  setUsuarioLogado,
  resetUser,
  resetWhitelabel,
  setLogos,
  setKeepQuitaLogo,
  setIdCompanyBind,
  setIsQuitaboletos,
  setWhatsappSuport,
  setAutenticacaoExterna,
  setCoresPadroes,
  setCompanyBase,
  setFirstAccess,
  setPermissions,
  setUrlLogo,
} = user.actions;
